// src/components/VideoAnalysisScreen.js
import React, { useState } from 'react';

const VideoAnalysisScreen = () => {
    const [videoUrl, setVideoUrl] = useState('');

    const handleAnalyze = () => {
        // Add your logic to analyze the video
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 border border-secondary-text rounded-custom shadow-lg">
            <div className="flex flex-col space-y-6">
                <h1 className="text-3xl font-semibold text-center">Video Analysis</h1>
                <div>
                    <label className="block mb-2">TikTok Video URL</label>
                    <input
                        type="url"
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                        placeholder="Enter TikTok video URL"
                        className="w-full p-2 border border-secondary-text rounded-custom"
                    />
                </div>
                <button
                    onClick={handleAnalyze}
                    className="w-full bg-cta-button text-primary-text px-4 py-2 rounded-custom hover:bg-[#e04328] transition-colors duration-300"
                >
                    Analyze Video
                </button>
            </div>
        </div>
    );
};

export default VideoAnalysisScreen;