import React, { useState } from 'react';

const FaqItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="border-b border-border last:border-0">
            <button
                className="w-full py-4 px-6 text-left flex justify-between items-center hover:bg-background-secondary transition-colors rounded-t-lg"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="font-semibold text-lg text-primary-text">{question}</span>
                <svg
                    className={`w-6 h-6 transform transition-transform ${isOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            {isOpen && (
                <div className="p-6 bg-background-secondary text-secondary-text">
                    {answer}
                </div>
            )}
        </div>
    );
};

export default FaqItem; 