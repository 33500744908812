import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import useTokenStore from './tokenStore';

const TokenBalance = ({ onClose }) => {
    const { tokens, transactions, fetchTokenBalance } = useTokenStore(state => ({
        tokens: state.tokens,
        transactions: state.transactions,
        fetchTokenBalance: state.fetchTokenBalance // Ensure this is included
    }));
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchBalance().then(r => console.log(r));
    }, []);

    const fetchBalance = async () => {
        try {
            setLoading(true);
            await fetchTokenBalance(); // This should now work as fetchTokenBalance is defined
        } catch (error) {
            console.error('Error fetching token balance:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePurchase = async (packageType) => {
        try {
            if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
                throw new Error('Stripe public key is not configured');
            }

            const token = localStorage.getItem('token');
            const response = await fetch('https://api.tiklink.nl/tokens/purchase', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ package: packageType })
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout session');
            }

            const { sessionId } = await response.json();
            const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

            if (!stripe) {
                throw new Error('Failed to initialize Stripe');
            }

            const { error } = await stripe.redirectToCheckout({ sessionId });
            if (error) {
                throw error;
            }
        } catch (error) {
            console.error('Error initiating purchase:', error);
        }
    };

    if (loading) return <div className="text-center p-4">Loading tokens...</div>;

    return (
      <div className="container mx-auto px-4 py-8 bg-background">
          <div className="flex justify-between items-center mb-4">
          </div>

          <div className={"container mx-auto px-4 pt-4 md:pt-20"}>
              <div className={"max-w-4xl mx-auto"}>
                  <h2 className="text-xl md:text-2xl font-semibold mb-6 text-center">Buy Analyze Tokens</h2>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      {/* Basic Card */}
                      <div
                        onClick={() => handlePurchase('basic')}
                        className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center cursor-pointer transition-all duration-300 hover:border-primary hover:shadow-xl"
                      >
                          <h3 className="text-2xl font-semibold mb-3 text-text-primary">Basic</h3>
                          <p className="text-lg mb-2">10 tokens</p>
                          <p className="text-primary mb-4">€5</p>
                          <button
                            onClick={() => handlePurchase('basic')}
                            className="w-full bg-primary text-text-primary px-4 py-2 rounded-lg hover:bg-primary-hover transition-colors"
                          >
                              Purchase
                          </button>
                      </div>

                      {/* Pro Card */}
                      <div
                        onClick={() => handlePurchase('pro')}
                        className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center cursor-pointer transition-all duration-300 hover:border-primary hover:shadow-xl"
                      >
                          <h3 className="text-2xl font-semibold mb-3 text-text-primary">Pro</h3>
                          <p className="text-lg mb-2">25 tokens</p>
                          <p className="text-primary mb-4">€10</p>
                          <button
                            onClick={() => handlePurchase('pro')}
                            className="w-full bg-primary text-text-primary px-4 py-2 rounded-lg hover:bg-primary-hover transition-colors"
                          >
                              Purchase
                          </button>
                      </div>

                      {/* Premium Card */}
                      <div
                        onClick={() => handlePurchase('premium')}
                        className="bg-background-secondary border-2 border-border rounded-custom p-8 flex flex-col items-center cursor-pointer transition-all duration-300 hover:border-primary hover:shadow-xl"
                      >
                          <h3 className="text-2xl font-semibold mb-3 text-text-primary">Premium</h3>
                          <p className="text-lg mb-2">100 tokens</p>
                          <p className="text-primary mb-4">€35</p>
                          <button
                            onClick={() => handlePurchase('premium')}
                            className="w-full bg-primary text-text-primary px-4 py-2 rounded-lg hover:bg-primary-hover transition-colors"
                          >
                              Purchase
                          </button>
                      </div>
                  </div>

                  {/* Transactions Table */}
                  <TokenTransactionsTable transactions={transactions} />
              </div>
          </div>
      </div>
    );
};

// Capitalizes the first letter of a string
const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// Formats the date string into a user-friendly format
const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
    return new Date(dateString).toLocaleString('en-GB', options).replace(',', ''); // Change 'en-GB' to your desired locale
};

const getBadgeColor = (type) => {
    switch (type) {
        case 'initial':
            return 'bg-blue-300'; // Blue for initial
        case 'purchase':
            return 'bg-success'; // Green for completed
        case 'use':
            return 'bg-red-300'; // Red for failed
        default:
            return 'bg-gray-300'; // Default gray for unknown types
    }
};

const TokenTransactionsTable = ({ transactions = [] }) => (
  <div className="mt-8">
      <h3 className="text-xl font-semibold mb-4">Token Transactions</h3>
      {transactions.length > 0 ? (
        <table className="min-w-full bg-background-secondary rounded-lg shadow-lg">
            <thead>
            <tr>
                <th className="px-4 py-2 border-b border-border text-left">Type</th>
                <th className="px-4 py-2 border-b border-border text-left">Amount</th>
                <th className="px-4 py-2 border-b border-border text-left">Date</th>
            </tr>
            </thead>
            <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className="hover:bg-background-hover transition-colors">
                  <td className="px-4 py-2 border-b border-border">
                            <span
                              className={`inline-block px-2 py-1 rounded-full text-white font-semibold ${getBadgeColor(transaction.type)}`}>
                                {capitalizeFirstLetter(transaction.type)}
                            </span>
                  </td>
                  <td className="px-4 py-2 border-b border-border">{transaction.amount}</td>
                  <td className="px-4 py-2 border-b border-border">{formatDate(transaction.timestamp)}</td>
              </tr>
            ))}
            </tbody>
        </table>
      ) : (
        <p className="text-center text-text-secondary">No transactions found.</p>
      )}
  </div>
);

export default TokenBalance;