import React from 'react';
import { useNavigate } from 'react-router-dom';

const InsufficientTokensModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-background-secondary rounded-lg p-6 max-w-md w-full mx-4">
        <h2 className="text-xl font-semibold mb-4 text-text-primary">Insufficient Tokens</h2>
        <p className="text-text-secondary mb-6">
          You are out of tokens. If you wish to continue, please purchase new tokens.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-text-secondary hover:text-text-primary transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => navigate('/dashboard/tokens')}
            className="bg-primary hover:bg-primary-hover text-text-primary px-6 py-2 rounded-lg transition-colors"
          >
            Buy Tokens
          </button>
        </div>
      </div>
    </div>
  );
};

export default InsufficientTokensModal;