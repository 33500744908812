// src/components/Hero.js
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import heroImg1 from '../assets/hero-img1.png';
import heroImg2 from '../assets/hero-img2.png';
import heroImg3 from '../assets/hero-img3.png';
import '../assets/css/Hero.css';

function Hero() {
    const scrollToFeatures = () => {
        const featuresSection = document.getElementById('features-section');
        if (featuresSection) {
            featuresSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    // Custom arrow components for slider
    const CustomPrevArrow = (props) => {
        const { onClick } = props;
        return (
          <button
            onClick={onClick}
            className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
            style={{ left: '8px' }}
          >
              ←
          </button>
        );
    };

    const CustomNextArrow = (props) => {
        const { onClick } = props;
        return (
          <button
            onClick={onClick}
            className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white rounded-full w-8 h-8 flex items-center justify-center transition-colors"
            style={{ right: '8px' }}
          >
              →
          </button>
        );
    };

    const sliderSettings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrows: false,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />
    };

    return (
      <section
        className="flex flex-col md:flex-row items-center justify-center max-w-7xl mx-auto px-4 sm:px-8 md:px-12 py-16 md:py-32 bg-background">

          {/* Text div */}
          <div className="w-full md:w-3/5 space-y-6 md:space-y-8 text-center md:text-left mb-8 md:mb-0 md:pr-8">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight">
                  Unlock Product Insights with AI-Powered Analysis
              </h1>
              <p className="text-base sm:text-lg md:text-xl text-secondary-text">
                  Transform your product research with our advanced AI tool. Upload any product image
                  and instantly get detailed insights, market trends, and competitive analysis to make
                  data-driven decisions for your e-commerce business.
              </p>
              <div
                className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 justify-center md:justify-start">
                  <Link to="/register"
                        className="bg-primary text-text-primary px-6 sm:px-8 py-3 sm:py-4 rounded-custom font-semibold hover:bg-primary-hover transition-colors">
                      Start Free Trial
                  </Link>
                  <button
                    onClick={scrollToFeatures}
                    className="bg-transparent border border-primary text-primary px-6 sm:px-8 py-3 sm:py-4 rounded-custom font-semibold hover:bg-primary hover:text-text-primary transition-all"
                  >
                      See How It Works
                  </button>
              </div>
          </div>

          {/* Slider div */}
          <div className="w-full md:w-2/5 md:pl-8">
              <div className="max-w-lg mx-auto">
                  <Slider {...sliderSettings}>
                      <div>
                          <img src={heroImg1} alt="AI Product Analysis Image 1"
                               className="w-full h-auto rounded-custom"/>
                      </div>
                      <div>
                          <img src={heroImg2} alt="AI Product Analysis Image 2"
                               className="w-full h-auto rounded-custom"/>
                      </div>
                      <div>
                          <img src={heroImg3} alt="AI Product Analysis Image 3"
                               className="w-full h-auto rounded-custom"/>
                      </div>
                  </Slider>
              </div>
          </div>
      </section>
    );
}

export default Hero;