// src/components/DashboardLayout.js
import React from 'react';
import Sidebar from './Sidebar';

const DashboardLayout = ({ children }) => {
    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <Sidebar />
            <div className="w-full md:ml-64 p-4 md:p-6 mt-16 md:mt-0">
                {children}
            </div>
        </div>
    );
};

export default DashboardLayout;