// src/components/Logout.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove the token from localStorage
        localStorage.removeItem('token');
        // Redirect to login
        navigate('/login');
    }, [navigate]);

    return null;
};

export default Logout;