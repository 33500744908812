// src/components/VideoUpload.js
import React, { useState } from 'react';
import VideoFrameSelector from './VideoFrameSelector';
import { Line } from 'react-chartjs-2';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoUpload = ({ onFrameSelected, isLoading, onBack }) => {
    const [videoFile, setVideoFile] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [showFrameSelector, setShowFrameSelector] = useState(false);
    const [error, setError] = useState(null);
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        const file = e.dataTransfer.files[0];
        handleFileSelect(file);
    };

    const handleFileSelect = (file) => {
        setError(null);

        if (file) {
            if (!file.type.startsWith('video/')) {
                setError('Please upload a valid video file');
                return;
            }

            if (videoUrl) {
                URL.revokeObjectURL(videoUrl);
            }

            const newVideoUrl = URL.createObjectURL(file);
            setVideoFile(file);
            setVideoUrl(newVideoUrl);
            setShowFrameSelector(true);
        }
    };

    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        handleFileSelect(file);
    };

    return (
      <div className="relative">
          <button
            onClick={onBack}
            className="text-primary hover:text-primary-hover transition-colors flex items-center space-x-2 mb-4"
          >
              <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M19 12H5M12 19l-7-7 7-7" />
              </svg>
              <span>Back</span>
          </button>

          {!showFrameSelector ? (
            <div
              className={`border-2 border-dashed rounded-lg p-8 text-center ${
                dragActive ? 'border-primary bg-primary/5' : 'border-border'
              }`}
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            >
                <input
                  type="file"
                  accept="video/*"
                  onChange={handleVideoUpload}
                  className="hidden"
                  id="video-upload"
                />
                <label
                  htmlFor="video-upload"
                  className="cursor-pointer flex flex-col items-center justify-center"
                >
                    <svg className="w-12 h-12 text-gray-400 mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 4v16M17 4v16M3 8h4m10 0h4M3 12h18M3 16h4m10 0h4M4 20h16a1 1 0 001-1V5a1 1 0 00-1-1H4a1 1 0 00-1 1v14a1 1 0 001 1z" />
                    </svg>
                    <p className="text-base text-gray-600">
                        Drag & drop your video here, or <span className="text-primary">browse</span>
                    </p>
                    <p className="text-sm text-gray-500 mt-2">
                        Supports: MP4
                    </p>
                </label>
            </div>
          ) : (
            <VideoFrameSelector
              videoUrl={videoUrl}
              onFrameSelect={onFrameSelected}
            />
          )}

          {error && (
            <div className="mt-4 p-4 bg-red-100 text-red-700 rounded-lg">
                {error}
            </div>
          )}
      </div>
    );
};

export default VideoUpload;