// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/NavbarLanding'; // If you have a public Navbar
import DashboardLayout from './components/DashboardLayout';
import VideoFrameSelector from './components/VideoFrameSelector';
import VideoUpload from './components/VideoUpload';
import MyProductsScreen from './screens/MyProductsScreen';
import ObjectDetection from './screens/ObjectDetection';
import ProductFoundScreen from './screens/ProductFoundScreen';
import VideoAnalysisScreen from './screens/VideoAnalysisScreen';
import Login from './screens/Login'; // Your Login component
import Register from './screens/Register'; // Your Register component
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './components/Logout'; // Optional Logout component
import WelcomePage from "./screens/WelcomePage";
import TrendingProductsScreen from './screens/TrendingProductsScreen';
import TokenBalance from './components/TokenBalance';
import useTokenStore from './components/tokenStore';

function App() {
    const fetchTokenBalance = useTokenStore(state => state.fetchTokenBalance);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchTokenBalance().then(r => console.log(r));
        }
    }, [fetchTokenBalance]);

    return (
        <Router>
            {/* Public Navbar (optional) */}
            {/* <Navbar /> */}
            <Routes>
                {/* Public Routes */}
                <Route path="/" element={<WelcomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                {/* Logout Route */}
                <Route path="/logout" element={<Logout />} />

                {/* Protected Dashboard Routes */}
                <Route
                    path="/dashboard/*"
                    element={
                        <ProtectedRoute>
                            <DashboardLayout>
                                <Routes>
                                    <Route path="video-analysis" element={<VideoAnalysisScreen />} />
                                    <Route path="my-products" element={<MyProductsScreen />} />
                                    <Route path="object-detection" element={<ObjectDetection />} />
                                    <Route path="product-found" element={<ProductFoundScreen />} />
                                    <Route path="trending" element={<TrendingProductsScreen />} />
                                    <Route path="tokens" element={<TokenBalance />} />
                                    {/* Add more protected routes here */}
                                    <Route path="*" element={<Navigate to="/dashboard/my-products" />} />
                                </Routes>
                            </DashboardLayout>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}

export default App;