import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineMail, AiOutlineLock, AiOutlineLogin } from 'react-icons/ai'; // Import React Icons
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon
import Logo from '../assets/LogoTikLinkmetTekst.png';

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [loading, setLoading] = useState(false); // State for loading spinner
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when login starts
        try {
            const response = await axios.post('https://api.tiklink.nl/login', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            localStorage.setItem('token', response.data.token);
            navigate('/dashboard');
        } catch (error) {
            console.error('❌ Login failed:', error.response ? error.response.data : error.message);
            setErrorMessage('Login failed: ' + (error.response ? error.response.data : error.message));
        } finally {
            setLoading(false); // Set loading to false after login attempt
        }
    };

    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-background">
          {/* Centered Logo */}
          <div className="flex justify-center mb-6">
              <a href={'/'} className="flex items-center space-x-2 text-primary-text">
                  <img src={Logo} alt="Logo" className="w-44 h-auto" />
              </a>
          </div>

          <div className="bg-navbar p-8 bg-background-secondary rounded-custom shadow-lg w-full max-w-md">
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4 text-center">
                    <span>{errorMessage}</span>
                </div>
              )}
              <h2 className="text-2xl font-semibold text-center text-primary-text mb-6">
                  Login
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                      <label className="block text-secondary-text mb-2">Email</label>
                      <div className="flex items-center bg-background text-primary-text border border-secondary-text rounded-custom">
                          <AiOutlineMail className="ml-2" />
                          <input
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-transparent focus:outline-none focus:border-cta-button"
                          />
                      </div>
                  </div>
                  <div>
                      <label className="block text-secondary-text mb-2">Password</label>
                      <div className="flex items-center bg-background text-primary-text border border-secondary-text rounded-custom">
                          <AiOutlineLock className="ml-2" />
                          <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            required
                            className="w-full px-4 py-2 bg-transparent focus:outline-none focus:border-cta-button"
                          />
                      </div>
                  </div>
                  <button
                    type="submit"
                    className="w-full bg-success hover:bg-green-300 text-text-primary px-4 py-2 rounded-custom transition-colors flex items-center justify-center"
                    disabled={loading} // Disable button while loading
                  >
                      {loading ? (
                        <FaSpinner className="animate-spin mr-2" /> // Spinner icon with animation
                      ) : (
                        'Login'
                      )}
                  </button>
              </form>
          </div>
      </div>
    );
}

export default Login;