// src/screens/WelcomePage.js
import React, { useState } from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Navbar from "../components/navbar";
import Footer from "../components/Footer";
import FaqItem from '../components/FaqItem';

function WelcomePage() {
    return (
      <div className="flex flex-col min-h-screen">
          <Navbar />
          <main className="flex-grow">
              <div className="pt-32">
                  <Hero />
              </div>
              <div className="my-24">
                  <section id="features-section" className="scroll-mt-30">
                      <Features />
                  </section>
              </div>

              <div className="my-24">
                  <section id="pricing-section" className="py-20 bg-background scroll-mt-32">
                      <div className="container mx-auto px-6">
                          <h2 className="text-3xl font-semibold text-center mb-6 text-primary-text">
                              Analysis Tokens
                          </h2>
                          <p className="text-center text-secondary-text max-w-2xl mx-auto mb-16">
                              Each product analysis costs 1 token. Our AI analyzes your product content, provides market insights,
                              and shows trending data to help you make informed decisions. Choose the package that fits your research needs.
                          </p>
                          <div className="mb-12 mt-12 max-w-3xl mx-auto bg-background rounded-lg shadow-lg overflow-hidden">
                              <FaqItem
                                question="How Tokens Work"
                                answer={
                                    <ul className="space-y-2">
                                        <li className="flex items-center">
                                            <span className="text-2xl mr-2">•</span>
                                            <span>Each token allows you to analyze one piece of content</span>
                                        </li>
                                        <li className="flex items-center">
                                            <span className="text-2xl mr-2">•</span>
                                            <span>Get detailed AI-powered product analysis</span>
                                        </li>
                                        <li className="flex items-center">
                                            <span className="text-2xl mr-2">•</span>
                                            <span>Access real-time market trend data</span>
                                        </li>
                                        <li className="flex items-center">
                                            <span className="text-2xl mr-2">•</span>
                                            <span>Tokens never expire</span>
                                        </li>
                                    </ul>
                                }
                              />
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                              {/* Basic Package */}
                              <div className="bg-background-secondary p-6 rounded-custom border-2 border-border hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                  <div className="text-center">
                                      <h3 className="text-2xl font-semibold mb-4">Basic</h3>
                                      <div className="text-4xl font-bold text-primary mb-2">$5</div>
                                      <div className="text-lg text-text-secondary mb-6">10 Tokens</div>
                                      <p className="text-secondary-text">
                                          Perfect for testing the waters and analyzing your first potential products.
                                      </p>
                                  </div>
                              </div>

                              {/* Pro Package */}
                              <div className="bg-background-secondary p-6 rounded-custom border-2 border-primary hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                  <div className="text-center">
                                      <h3 className="text-2xl font-semibold mb-4">Pro</h3>
                                      <div className="text-4xl font-bold text-primary mb-2">$10</div>
                                      <div className="text-lg text-text-secondary mb-6">25 Tokens</div>
                                      <p className="text-secondary-text">
                                          Most popular choice for finding your next winning product.
                                      </p>
                                  </div>
                              </div>

                              {/* Premium Package */}
                              <div className="bg-background-secondary p-6 rounded-custom border-2 border-border hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                  <div className="text-center">
                                      <h3 className="text-2xl font-semibold mb-4">Premium</h3>
                                      <div className="text-4xl font-bold text-primary mb-2">$35</div>
                                      <div className="text-lg text-text-secondary mb-6">100 Tokens</div>
                                      <p className="text-secondary-text">
                                          Best value for extensive product research and market analysis.
                                      </p>
                                  </div>
                              </div>
                          </div>


                      </div>
                  </section>
              </div>

              <div className="my-24">
                  <section id="faq-section" className="py-10 bg-navbar scroll-mt-32">
                      <div className="container mx-auto px-6">
                          <h2 className="text-3xl font-semibold text-center mb-16 text-primary-text">
                              Frequently Asked Questions
                          </h2>
                          <div className="max-w-3xl mx-auto bg-background rounded-lg shadow-lg overflow-hidden">
                              <FaqItem
                                question="What is a token?"
                                answer="A token is a credit that allows you to analyze one piece of content. Each time you upload content for analysis, one token is used to process your request and provide market insights."
                              />
                              <FaqItem
                                question="Do tokens expire?"
                                answer="No, your tokens never expire. You can use them whenever you want to analyze a product."
                              />
                              <FaqItem
                                question="What do I get with each analysis?"
                                answer="Each analysis includes AI-powered product identification, market trend data, and popularity metrics to help you make informed decisions about product potential."
                              />
                              <FaqItem
                                question="Can I get a refund for unused tokens?"
                                answer="Due to the digital nature of tokens, we cannot offer refunds for purchased tokens. Make sure to choose the package that best fits your needs."
                              />
                              <FaqItem
                                question="How accurate is the AI analysis?"
                                answer="Our AI system is continuously trained on the latest product data and market trends. While no AI is perfect, we strive to provide highly accurate insights to help inform your product decisions."
                              />
                          </div>
                      </div>
                  </section>
              </div>

              <div className="my-24">
                  <ContactSection />
              </div>
          </main>
          <Footer />
      </div>
    );
}

const ContactSection = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        // Reset form fields if needed
        e.target.reset();

        // Reset success message after 5 seconds
        setTimeout(() => {
            setIsSubmitted(false);
        }, 5000);
    };

    return (
      <section id="contact-section" className="py-10 bg-background scroll-mt-32">
          <div className="container mx-auto px-6">
              <h2 className="text-3xl font-semibold text-center mb-16 text-primary-text">
                  Get in Touch
              </h2>
              <div className="max-w-2xl mx-auto">
                  {isSubmitted ? (
                    <div className="text-center p-6 bg-green-100 rounded-custom">
                        <svg className="w-16 h-16 mx-auto text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                        <p className="text-green-700 font-semibold text-lg">Your message has been sent!</p>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label htmlFor="name" className="block text-primary-text mb-2">
                                    Name
                                </label>
                                <input
                                  type="text"
                                  id="name"
                                  required
                                  className="w-full px-4 py-2 rounded-custom bg-background-secondary border border-border focus:border-primary focus:outline-none transition-colors"
                                  placeholder="Your name"
                                />
                            </div>
                            <div>
                                <label htmlFor="email" className="block text-primary-text mb-2">
                                    Email
                                </label>
                                <input
                                  type="email"
                                  id="email"
                                  required
                                  className="w-full px-4 py-2 rounded-custom bg-background-secondary border border-border focus:border-primary focus:outline-none transition-colors"
                                  placeholder="your@email.com"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-primary-text mb-2">
                                Message
                            </label>
                            <textarea
                              id="message"
                              rows={6}
                              required
                              className="w-full px-4 py-2 rounded-custom bg-background-secondary border border-border focus:border-primary focus:outline-none transition-colors resize-none"
                              placeholder="Your message..."
                            />
                        </div>
                        <div className="text-center">
                            <button
                              type="submit"
                              className="bg-primary hover:bg-primary-hover text-text-primary px-8 py-3 rounded-custom transition-colors font-semibold"
                            >
                                Send Message
                            </button>
                        </div>
                    </form>
                  )}
              </div>
          </div>
      </section>
    );
};

export default WelcomePage;