// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/LogoTikLinkmetTekst.png';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
            setIsMenuOpen(false);
        }
    };

    return (
      <nav className="background-secondary text-primary-text px-4 sm:px-6 py-4 fixed w-full top-0 z-50 shadow-lg backdrop-blur-2xl bg-opacity-95">
          <div className="container mx-auto flex justify-between items-center">
              <div className="text-2xl font-bold">
                  <Link to="/">
                      <img src={Logo} alt="TikLink Logo" className="h-20 sm:h-24 md:h-32 w-auto" />
                  </Link>
              </div>

              {/* Mobile menu button */}
              <button
                className="md:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Toggle menu"
              >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
                  </svg>
              </button>

              {/* Desktop menu */}
              <ul className="hidden md:flex md:space-x-4 items-center">
                  <li>
                      <button
                        onClick={() => scrollToSection('features-section')}
                        className="bg-white px-4 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800"
                      >
                          Features
                      </button>
                  </li>
                  <li>
                      <button
                        onClick={() => scrollToSection('pricing-section')}
                        className="bg-white px-4 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800"
                      >
                          Pricing
                      </button>
                  </li>
                  <li>
                      <button
                        onClick={() => scrollToSection('faq-section')}
                        className="bg-white px-4 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800"
                      >
                          FAQ
                      </button>
                  </li>
                  <li>
                      <button
                        onClick={() => scrollToSection('contact-section')}
                        className="bg-white px-4 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800"
                      >
                          Contact
                      </button>
                  </li>
                  <li>
                      <Link
                        to="/login"
                        className="bg-white px-4 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800"
                      >
                          Login
                      </Link>
                  </li>
                  <li>
                      <Link
                        to="/register"
                        className="bg-primary text-text-primary px-4 py-2 rounded-custom hover:bg-primary-hover transition-colors"
                      >
                          Get Started
                      </Link>
                  </li>
              </ul>
          </div>

          {/* Mobile menu - Centered items with full-width background */}
          {isMenuOpen && (
            <div className="absolute left-0 right-0 bg-background-secondary shadow-lg md:hidden">
                <div className="container mx-auto px-4 py-4 flex flex-col items-center space-y-4">
                    <button
                      onClick={() => scrollToSection('features-section')}
                      className="bg-white px-8 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800 w-48 text-center"
                    >
                        Features
                    </button>
                    <button
                      onClick={() => scrollToSection('pricing-section')}
                      className="bg-white px-8 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800 w-48 text-center"
                    >
                        Pricing
                    </button>
                    <button
                      onClick={() => scrollToSection('contact-section')}
                      className="bg-white px-8 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800 w-48 text-center"
                    >
                        Contact
                    </button>
                    <button
                      onClick={() => scrollToSection('faq-section')}
                      className="bg-white px-8 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800 w-48 text-center"
                    >
                        FAQ
                    </button>
                    <Link
                      to="/login"
                      className="bg-white px-8 py-2 rounded-custom hover:bg-gray-100 transition-colors text-gray-800 w-48 text-center"
                    >
                        Login
                    </Link>
                    <Link
                      to="/register"
                      className="bg-primary text-text-primary px-8 py-2 rounded-custom hover:bg-primary-hover transition-colors w-48 text-center"
                    >
                        Get Started
                    </Link>
                </div>
            </div>
          )}
      </nav>
    );
}

export default Navbar;