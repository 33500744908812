// src/components/VideoFrameSelector.js
import React, { useState, useRef, useEffect } from 'react';

const VideoFrameSelector = ({ videoUrl, onFrameSelect }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const captureFrame = async () => {
        try {
            setIsLoading(true);
            const video = videoRef.current;
            const canvas = canvasRef.current;

            if (!video || !canvas) return;

            const context = canvas.getContext('2d');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const blob = await new Promise(resolve => {
                canvas.toBlob(resolve, 'image/jpeg', 0.95);
            });

            await onFrameSelect(blob, video.currentTime);
        } catch (err) {
            setError('Failed to capture frame');
            console.error('Frame capture error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
      <div className="space-y-4">
          <div className="flex justify-center">
              <video
                ref={videoRef}
                src={videoUrl}
                controls
                style={{ maxHeight: '300px', width: 'auto' }}
                className="mx-auto rounded-custom"
              />
          </div>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
          <button
            onClick={captureFrame}
            disabled={isLoading}
            className="w-full bg-primary hover:bg-primary-hover text-text-primary py-4 rounded-custom transition-colors disabled:opacity-50 disabled:cursor-not-allowed font-semibold flex items-center justify-center space-x-2"
          >
              {isLoading ? (
                <>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Analyzing Frame...</span>
                </>
              ) : (
                <span>Analyze Frame</span>
              )}
          </button>
          {error && (
            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded-custom">
                {error}
            </div>
          )}
      </div>
    );
};

export default VideoFrameSelector;