import React from 'react';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, message }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-background rounded-custom w-full max-w-sm">
                <div className="p-6">
                    <h3 className="text-xl font-semibold text-text-primary mb-4">
                        Confirm Delete
                    </h3>
                    <p className="text-text-secondary mb-6">
                        {message}
                    </p>
                    <div className="flex justify-end space-x-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-background-secondary text-text-primary rounded-custom hover:bg-background-accent transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onConfirm}
                            className="px-4 py-2 bg-error hover:bg-red-300 text-text-primary rounded-custom transition-colors"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
