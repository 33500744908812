// src/components/ProductFoundScreen.js
import React from 'react';

const ProductFoundScreen = () => {
    const products = [
        { id: 1, name: 'Detected Product 1', price: '$10', availability: 'In Stock' },
        { id: 2, name: 'Detected Product 2', price: '$15', availability: 'Out of Stock' }
    ];

    return (
        <div className="p-8 bg-background">
            <h2 className="text-2xl font-semibold mb-4 text-text-primary">Product Found</h2>
            <div className="space-y-4">
                {products.map(product => (
                    <div key={product.id} className="p-4 bg-background-secondary border border-border rounded-custom shadow-lg">
                        <h3 className="text-xl font-medium text-text-primary">{product.name}</h3>
                        <p className="text-text-secondary">Price: {product.price}</p>
                        <p className="text-text-secondary">Availability: {product.availability}</p>
                        <button className="mt-2 bg-primary hover:bg-primary-hover text-text-primary px-4 py-2 rounded-custom transition-colors">
                            Add to Store
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductFoundScreen;